body {
  font-family: "Arial", sans-serif; }

html {
  font-size: 0.875rem;
  font-family: "Arial", sans-serif; }

@media (min-width: 544px) {
  html {
    font-size: 1rem; } }

@media (min-width: 840px) {
  html {
    font-size: 1.125rem; } }

h1 {
  font-size: 30px;
  font-weight: 900; }

h2 {
  font-size: 26px;
  font-weight: 900; }

h3 {
  font-size: 20px;
  font-weight: 900;
  text-align: center; }

h4 {
  font-size: 15px;
  font-weight: 600; }

h5 {
  font-weight: 600; }

.storepage {
  width: 100%; }
  .storepage .row {
    margin: 0; }
    .storepage .row .col-12 {
      padding: 0; }
    .storepage .row .col-sm-6, .storepage .row .col-6 {
      padding: 0;
      margin: 0; }
      .storepage .row .col-sm-6.skp-line-ver, .storepage .row .col-6.skp-line-ver {
        margin: 0; }
  .storepage figure {
    margin-bottom: 0; }
  @media (min-width: 544px) {
    .storepage .row, .storepage .container-fluid, .storepage .SKPContainer, .storepage .border-slider, .storepage .row-product-tile {
      position: relative; }
      .storepage .row::before, .storepage .container-fluid::before, .storepage .SKPContainer::before, .storepage .border-slider::before, .storepage .row-product-tile::before {
        position: absolute;
        content: none;
        background-color: #CCCCCC;
        height: 100%;
        width: 0.5px;
        z-index: 99;
        bottom: 0; }
      .storepage .row::after, .storepage .container-fluid::after, .storepage .SKPContainer::after, .storepage .border-slider::after, .storepage .row-product-tile::after {
        position: absolute;
        content: none;
        background-color: #CCCCCC;
        height: 100%;
        width: 0.5px;
        z-index: 99;
        bottom: 0; }
      .storepage .row.border_top, .storepage .container-fluid.border_top, .storepage .SKPContainer.border_top, .storepage .border-slider.border_top, .storepage .row-product-tile.border_top {
        border-top: solid 1px #CCCCCC;
        border-bottom: none; }
      .storepage .row.border_bottom, .storepage .container-fluid.border_bottom, .storepage .SKPContainer.border_bottom, .storepage .border-slider.border_bottom, .storepage .row-product-tile.border_bottom {
        border-bottom: solid 1px #CCCCCC;
        border-top: none; }
      .storepage .row.border_top_bottom, .storepage .container-fluid.border_top_bottom, .storepage .SKPContainer.border_top_bottom, .storepage .border-slider.border_top_bottom, .storepage .row-product-tile.border_top_bottom {
        border-bottom: solid 1px #CCCCCC;
        border-top: solid 1px #CCCCCC; }
      .storepage .row.no_border, .storepage .container-fluid.no_border, .storepage .SKPContainer.no_border, .storepage .border-slider.no_border, .storepage .row-product-tile.no_border {
        border: none; }
      .storepage .row.border_right_desktop::before, .storepage .container-fluid.border_right_desktop::before, .storepage .SKPContainer.border_right_desktop::before, .storepage .border-slider.border_right_desktop::before, .storepage .row-product-tile.border_right_desktop::before {
        right: 0;
        content: ""; }
      .storepage .row.border_left_desktop::after, .storepage .container-fluid.border_left_desktop::after, .storepage .SKPContainer.border_left_desktop::after, .storepage .border-slider.border_left_desktop::after, .storepage .row-product-tile.border_left_desktop::after {
        left: 0;
        content: ""; }
      .storepage .row.border_right_Left_desktop::after, .storepage .container-fluid.border_right_Left_desktop::after, .storepage .SKPContainer.border_right_Left_desktop::after, .storepage .border-slider.border_right_Left_desktop::after, .storepage .row-product-tile.border_right_Left_desktop::after {
        left: 0;
        content: ""; }
      .storepage .row.border_right_Left_desktop::before, .storepage .container-fluid.border_right_Left_desktop::before, .storepage .SKPContainer.border_right_Left_desktop::before, .storepage .border-slider.border_right_Left_desktop::before, .storepage .row-product-tile.border_right_Left_desktop::before {
        right: 0;
        content: ""; }
      .storepage .row.no_border_Right_Left_desktop::after, .storepage .container-fluid.no_border_Right_Left_desktop::after, .storepage .SKPContainer.no_border_Right_Left_desktop::after, .storepage .border-slider.no_border_Right_Left_desktop::after, .storepage .row-product-tile.no_border_Right_Left_desktop::after {
        content: none; }
      .storepage .row.no_border_Right_Left_desktop::before, .storepage .container-fluid.no_border_Right_Left_desktop::before, .storepage .SKPContainer.no_border_Right_Left_desktop::before, .storepage .border-slider.no_border_Right_Left_desktop::before, .storepage .row-product-tile.no_border_Right_Left_desktop::before {
        content: none; } }
  @media (max-width: 543.98px) {
    .storepage .row, .storepage .container-fluid, .storepage .SKPContainer, .storepage .border-slider, .storepage .row-product-tile {
      position: relative; }
      .storepage .row::before, .storepage .container-fluid::before, .storepage .SKPContainer::before, .storepage .border-slider::before, .storepage .row-product-tile::before {
        position: absolute;
        content: none;
        background-color: #CCCCCC;
        height: 100%;
        width: 0.5px;
        z-index: 99;
        bottom: 0; }
      .storepage .row::after, .storepage .container-fluid::after, .storepage .SKPContainer::after, .storepage .border-slider::after, .storepage .row-product-tile::after {
        position: absolute;
        content: none;
        background-color: #CCCCCC;
        height: 100%;
        width: 0.5px;
        z-index: 99;
        bottom: 0; }
      .storepage .row.border_top_mobile, .storepage .container-fluid.border_top_mobile, .storepage .SKPContainer.border_top_mobile, .storepage .border-slider.border_top_mobile, .storepage .row-product-tile.border_top_mobile {
        border-top: solid 1px #CCCCCC;
        border-bottom: none; }
      .storepage .row.border_bottom_mobile, .storepage .container-fluid.border_bottom_mobile, .storepage .SKPContainer.border_bottom_mobile, .storepage .border-slider.border_bottom_mobile, .storepage .row-product-tile.border_bottom_mobile {
        border-bottom: solid 1px #CCCCCC;
        border-top: none; }
      .storepage .row.border_top_bottom_mobile, .storepage .container-fluid.border_top_bottom_mobile, .storepage .SKPContainer.border_top_bottom_mobile, .storepage .border-slider.border_top_bottom_mobile, .storepage .row-product-tile.border_top_bottom_mobile {
        border-bottom: solid 1px #CCCCCC;
        border-top: solid 1px #CCCCCC; }
      .storepage .row.no_border_mobile, .storepage .container-fluid.no_border_mobile, .storepage .SKPContainer.no_border_mobile, .storepage .border-slider.no_border_mobile, .storepage .row-product-tile.no_border_mobile {
        border: none; }
      .storepage .row.border_right_mobile::before, .storepage .container-fluid.border_right_mobile::before, .storepage .SKPContainer.border_right_mobile::before, .storepage .border-slider.border_right_mobile::before, .storepage .row-product-tile.border_right_mobile::before {
        right: 0;
        content: ""; }
      .storepage .row.border_left_mobile::after, .storepage .container-fluid.border_left_mobile::after, .storepage .SKPContainer.border_left_mobile::after, .storepage .border-slider.border_left_mobile::after, .storepage .row-product-tile.border_left_mobile::after {
        left: 0;
        content: ""; }
      .storepage .row.border_right_Left_mobile::after, .storepage .container-fluid.border_right_Left_mobile::after, .storepage .SKPContainer.border_right_Left_mobile::after, .storepage .border-slider.border_right_Left_mobile::after, .storepage .row-product-tile.border_right_Left_mobile::after {
        left: 0;
        content: ""; }
      .storepage .row.border_right_Left_mobile::before, .storepage .container-fluid.border_right_Left_mobile::before, .storepage .SKPContainer.border_right_Left_mobile::before, .storepage .border-slider.border_right_Left_mobile::before, .storepage .row-product-tile.border_right_Left_mobile::before {
        right: 0;
        content: ""; }
      .storepage .row.no_border_Right_Left_mobile::after, .storepage .container-fluid.no_border_Right_Left_mobile::after, .storepage .SKPContainer.no_border_Right_Left_mobile::after, .storepage .border-slider.no_border_Right_Left_mobile::after, .storepage .row-product-tile.no_border_Right_Left_mobile::after {
        content: none; }
      .storepage .row.no_border_Right_Left_mobile::before, .storepage .container-fluid.no_border_Right_Left_mobile::before, .storepage .SKPContainer.no_border_Right_Left_mobile::before, .storepage .border-slider.no_border_Right_Left_mobile::before, .storepage .row-product-tile.no_border_Right_Left_mobile::before {
        content: none; } }

.border-slider {
  position: relative; }
  .border-slider::before {
    position: absolute;
    content: none;
    background-color: #CCCCCC;
    height: 100%;
    width: 0.5px;
    z-index: 99;
    bottom: 0; }
  .border-slider::after {
    position: absolute;
    content: none;
    background-color: #CCCCCC;
    height: 100%;
    width: 0.5px;
    z-index: 99;
    bottom: 0; }
  .border-slider .slick-slide {
    line-height: 0; }
    .border-slider .slick-slide .border_top_mobile {
      border-top: none;
      border-bottom: none; }
    .border-slider .slick-slide .border_bottom_mobile {
      border-bottom: none;
      border-top: none; }
    .border-slider .slick-slide .border_top_bottom_mobile {
      border-bottom: none;
      border-top: none; }
    .border-slider .slick-slide .no_border_mobile {
      border: none; }
    .border-slider .slick-slide .border_right_desktop::before {
      right: 0;
      content: ""; }
    .border-slider .slick-slide .border_left_desktop::after {
      left: 0;
      content: ""; }
    .border-slider .slick-slide .border_right_Left_desktop::after {
      left: 0;
      content: ""; }
    .border-slider .slick-slide .border_right_Left_desktop::before {
      right: 0;
      content: ""; }
    .border-slider .slick-slide .no_border_Right_Left_desktop::after {
      content: none; }
    .border-slider .slick-slide .no_border_Right_Left_desktop::before {
      content: none; }
